export default class WaitingUpdatesChannel {
  constructor (channelData, callback) {
    this._cable = window.ActionCable.createConsumer()
    this._callback = callback

    this._subscription = this._cable.subscriptions.create(channelData, {
      received: this.onReceived
    })
  }

  unsubscribe = () => this._subscription.unsubscribe()

  onReceived = (data) => {
    switch (data.event) {
      case 'PERCENT_CHANGED':
        this._callback(data.payload)
        break
      case 'FORCE_PAGE_RELOAD':
        window.location.reload(true)
        break
      case 'PAGE_DATA_CHANGED':
        this._callback ? this._callback(data) : window.location.reload(true)
      default:
    }
  }
}
