class Handler {
  constructor ($current) {
    this.$current = $current
    this.$confirm = $('#default_confirmation')
    this.$body    = $('.modal-body p', this.$confirm)

    this.defaults = {
      bodyText: this.$body.text()
    }

    let bodyText = this.$current.data('body')
    if (bodyText) this.$body.text(bodyText)

    this.$confirm.on('click', '.btn-primary', function (e) {
      this.ok(e)
    }.bind(this))

    this.$confirm.on('click', '.btn-secondary', function (e) {
      this.cancel(e)
    }.bind(this))

    this.$confirm.modal('show')
  }

  ok (e) {
    this.$confirm.modal('hide')
    this.restoreDefaults()
  }

  cancel(e) {
    this.$confirm.modal('hide')
    this.restoreDefaults()
  }

  restoreDefaults () {
    if (this.defaults.bodyText != this.$body.text()) {
      this.$body.text(this.defaults.bodyText)
    }
  }
}

class DefaultHanlder extends Handler {
  constructor (event) {
    super(event)
  }

  ok (e) {
    this.$current.trigger('click', [true])
  }

  cancel (e) {
    super.cancel(e)
  }

}

export default {
  DefaultHanlder
}
