import { connect } from 'react-redux'
import { loadAdditionalFormFields, types } from 'actions/projects/form_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.LOAD_PROJECT_GROUPS, types.LOAD_ADDITIONAL_FORM_FIELDS])

export default connect((state) => ({
  groups: state.getIn(['projects', 'form', 'groups']).toJS(),
  locationId: state.getIn(['projects', 'form', 'locationId']),
  navOrderId: state.getIn(['projects', 'form', 'navOrderId']),
  jobNumber: state.getIn(['projects', 'form', 'jobNumber']),
  disabledFields: state.getIn(['projects', 'form', 'disabledFields'])?.toJS(),
  fieldsHtml: state.getIn(['projects', 'form', 'fieldsHtml']),
  isFetching: loadingSelector(state)
}), { loadAdditionalFormFields })
