const ajax = window.ajax

class CampaignDateHandler {

  constructor (options) {
    this.options = options
  }

  updatedTo = (newValue) => {
    const data = {}
    data[this.options.name] = newValue.format(this.options.DATE_FORMAT)
    return ajax({
      url: this.options.url,
      method: 'patch',
      data: { campaign: data, step: 'overview_date_update', format: 'json' }
    }).then((resp) => newValue )
  }
}

const AllHandlers = {
  CampaignDateHandler
}

const changeHandlerFor = (handlerName) => AllHandlers[handlerName]

export default changeHandlerFor
