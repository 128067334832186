class DeleteButton {
  static initClass() {
    this.CLASS_NAME = "pswp__button--delete";
  }

  constructor(pswp, framework, element) {
    this.framework = framework
    this.pswp = pswp
    this.element = element
  }

  onClick() {
    if (!confirm(I18n.t("uploader.are_you_sure_to_delete"))) { return }

    const item = this.pswp.currItem
    const index = this.pswp.getCurrentIndex()

    // adjust gallery
    if (this.pswp.items.length === 1) { // close the gallery if last item is removed
      this.pswp.options.hideAnimationDuration = 0
      this.pswp.close()
    } else { // go back 1 slide
      this.pswp.goTo(this.nextItemIndex(index))
      this.pswp.items.splice(index, 1)
      this.pswp.invalidateCurrItems()
      this.pswp.updateSize(true)
    }

    const data = {}
    const param = $("meta[name=csrf-param]").attr("content")
    const token = $("meta[name=csrf-token]").attr("content")
    data[param] = token
    return $.ajax({
      type: "DELETE",
      url: item.destroyUrl,
      data
    })
  }

  onAfterChange() {
    if(this.pswp.currItem.destroyUrl) {
      return this.element.style.display = "block"
    } else {
      return this.element.style.display = "none"
    }
  }

  nextItemIndex(index) {
    let nextIndex = index - 1
    if (nextIndex < 0) { nextIndex = 0 }
    return nextIndex
  }
}
DeleteButton.initClass()

export default DeleteButton
