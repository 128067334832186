import React, { useState } from 'react'
import Select from 'components/shared/base_react_select'
import HiddenInputs from 'components/shared/hidden_inputs'

import SelectorInterfaces from 'components/shared/selector_interfaces'

const elementInterface = new SelectorInterfaces.ElementItemInterface()

export default (props) => {

  const { name, options, multi } = props

  const valuesKey  = name.match(/\[(.*?)\]/)[1]
  const optionsKey = valuesKey + '_options'
  const [value, setValue] = useState(props.value)
  const onChange = (ops) => setValue(ops)

  return (
    <div>
      <Select
        isMulti={multi}
        value={value}
        options={options}
        onChange={onChange}
      />
      <HiddenInputs items={value} name={name} />
    </div>
  )
}
