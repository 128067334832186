import React from 'react'
import {SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import store from 'stores/photoswipe_store'

const DragHandle = SortableHandle(({position, onClick}) =>
  <i className="fa fa-image" onClick={ (e) => onClick(e, position) }></i>
)

const SortableItem = SortableElement(({active, position, onClick}) => {
  return (
    <li className={ active ? 'active' : ''} >
      <DragHandle  position={ position } onClick={ onClick } />
    </li>
  )
})

const SortableList = SortableContainer(({items, currentIndex, onClick}) => {
  return (
    <ul>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          position={index}
          onClick={ onClick }
          active={ index === currentIndex }
        />
      ))}
    </ul>
  )
})

class SortableBar extends React.Component {

  constructor (props) {
    super(props)
    this.listener = store.addListener('PswpChanged', () => this.forceUpdate() )
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    let items = store.galleryItems,
        currentItem = items[oldIndex],
        position = newIndex + 1
    store.updatePosition(currentItem.updatePositionUrl, position).then( (resp) => {
      store.galleryItems = arrayMove(items, oldIndex, newIndex)
      currentItem.position = position
      store.currPswpGallery.goTo(newIndex)
    })
  }

  onClickItem = (event, index) => {
    store.currPswpGallery.goTo(index)
  }

  render () {
    const { active } = this.props
    if (!active) { return null }

    const items = store.galleryItems
    const currentIndex = items.indexOf(store.currentItem)
    return (
      <div className="pswp__sort-bar">
        <SortableList
          items={items}
          onSortEnd={ this.onSortEnd }
          currentIndex={ currentIndex }
          useDragHandle={ true }
          axis="x"
          onClick={ this.onClickItem }
          distance={ 5 }
        />
      </div>
    )
  }
}

export default SortableBar
