import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SmModalWindow from 'components/shared/modal_window'
import Spinner from 'components/shared/spinner'
import CreateInstallerForm from './create_installer_form'

const { I18n } = window

function CreateInstallerButton({
  availableVendors,
  saveUser,
  isFetching,
  createInstallerError,
  createInstallerSuccess,
  resetCreateInstallerStatusMessages,
  buttonTitle, // = I18n.t('project_tickets.create_installer_window.button')
  windowTitle // = I18n.t('project_tickets.create_installer_window.create_installer_window_title')
}) {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [data, setData] = useState({
    company_id: availableVendors[0] && availableVendors[0]['value'],
    name: '',
    email: '',
    password: '',
    send_invitation_email: false,
    generate_random_password: true,
  })

  useEffect(() => {
    if (dialogOpened === false) {
      resetCreateInstallerStatusMessages()
    }
  }, [dialogOpened])

  const formRef = useRef(null)

  const handleSave = () => {
    const f = formRef.current
    const valid = f.checkValidity()
    if (!valid) {
      f.reportValidity()
    } else {
      saveUser(data)
    }
    return valid
  }

  const modalContent = (
    <>
      {createInstallerError && <div className="text-danger text-center">{createInstallerError}</div>}
      {createInstallerSuccess && <div className="text-success text-center">{createInstallerSuccess}</div>}
      {!createInstallerError && !createInstallerSuccess && (
        <div className="text-left">
          <CreateInstallerForm availableVendors={availableVendors} data={data} setData={setData} formRef={formRef} />
        </div>
      )}
    </>
  )
  const modalButtons = (
    <div className="buttons-container">
      <button
        className="btn btn-primary"
        type="button"
        disabled={isFetching || createInstallerError || createInstallerSuccess}
        onClick={handleSave}
      >
        {isFetching ? <Spinner className="ml-3 mr-3" viewType="inline" size="1" /> : I18n.t('application.actions.save')}
      </button>
    </div>
  )
  return (
    <>
      <button className="btn btn-outline-secondary w-100" type="button" onClick={() => setDialogOpened(true)}>
        <i className="fa fa-icon fa-plus mr-2" />
        {buttonTitle}
      </button>
      <SmModalWindow
        title={windowTitle}
        visible={dialogOpened}
        onClose={(e) => {
          setDialogOpened(false)
        }}
        content={modalContent}
        buttons={modalButtons}
        windowID="new_simplified_installer"
      />
    </>
  )
}

CreateInstallerButton.propTypes = {
  availableVendors: PropTypes.array.isRequired,
  saveUser: PropTypes.func.isRequired,
}

export default CreateInstallerButton
