import { EventEmitter } from 'fbemitter'

class ProjectFormStore extends EventEmitter {

  constructor () {
    super()
    this.items = { device_ids: [], element_ids: [] }
  }

  load (url) {
    return ajax({ url: url, method: 'get'}).then(function(resp) {
      this.items = {
        device_ids_options: resp.data.device_ids_options,
        element_ids_options: resp.data.element_ids_options,
        device_ids: [],
        element_ids: []
      }
      this.emit('NewLocationItems')
    }.bind(this))
  }

}

export default new ProjectFormStore()
