import React from 'react'
import PhotoswipeLink from './photoswipe_link'
import store from 'stores/photoswipe_store'

const MAX_SIZE = 3

// Single link to a gallery of images
export default class PhotoswipeGalleryLink extends React.Component {
  UNSAFE_componentWillMount () {
    this.items = []
    this.props.images.forEach( (image, idx) => {
      // we should add manually images to store, which are not rendered through PhotoswipeLink
      if (idx > MAX_SIZE - 1) {
        this.items.push(store.addImage(image, image.galleryId))
      }
    })
  }

  componentWillUnmount () {
    const galleryId = this.props.galleryId
    this.items.forEach( item => store.removeImage(item, galleryId) )
  }

  render () {
    const { images } = this.props
    return (
      <div className="position-relative">
        {images.slice(0, MAX_SIZE).reverse().map((image, index) =>
          <PhotoswipeLink preview key={index} {...image} style={{ top: `${index * 5}px`, left: `${index * 5}px` }}>
            <img src={image.thumbUrl} alt={this.props.img_alt} />
          </PhotoswipeLink>
        )}
      </div>
    )
  }
}
