import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import SmModalWindow from '../../modal_window'
import Spinner from '../../spinner'
import CreateLocationForm from './create_location_form'

const { I18n } = window as any

function CreateInstallerButton({
  saveLocation,
  isFetching,
  createLocationError,
  createLocationSuccess,
  resetCreateLocationStatusMessages,
  buttonTitle,
  windowTitle,
}) {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [data, setData] = useState({
    name: '',
    number: '',
  })

  useEffect(() => {
    if (dialogOpened === false) {
      resetCreateLocationStatusMessages()
    }
  }, [dialogOpened])

  const formRef = useRef(null)

  const handleSave = () => {
    const f = formRef.current
    const valid = f.checkValidity()
    if (!valid) {
      f.reportValidity()
    } else {
      saveLocation(data)
    }
    return valid
  }

  const modalContent = (
    <>
      {createLocationError && <div className="text-danger text-center">{createLocationError}</div>}
      {createLocationSuccess && <div className="text-success text-center">{createLocationSuccess}</div>}
      {!createLocationError && !createLocationSuccess && (
        <div className="text-left">
          <CreateLocationForm data={data} setData={setData} formRef={formRef} />
        </div>
      )}
    </>
  )
  const modalButtons = (
    <div className="buttons-container">
      <button
        className="btn btn-primary"
        type="button"
        disabled={isFetching || createLocationError || createLocationSuccess}
        onClick={handleSave}
      >
        {isFetching ? <Spinner className="ml-3 mr-3" viewType="inline" size="1" /> : I18n.t('application.actions.save')}
      </button>
    </div>
  )
  return (
    <>
      <button className="btn btn-outline-secondary w-100" type="button" onClick={() => setDialogOpened(true)}>
        <i className="fa fa-icon fa-plus mr-2" />
        {buttonTitle}
      </button>
      <SmModalWindow
        title={windowTitle}
        visible={dialogOpened}
        onClose={(e) => {
          setDialogOpened(false)
        }}
        content={modalContent}
        buttons={modalButtons}
        windowID="new_location_popup"
      />
    </>
  )
}

export default CreateInstallerButton
