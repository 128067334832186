import React, { Fragment } from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import Spinner from './spinner'

class SpinnerApp extends React.Component {

  render () {
    return (
      <Provider store={store}>
        <Spinner />
      </Provider>
    )
  }
}

export default SpinnerApp
