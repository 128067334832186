import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Photoswipe from 'photoswipe'
import RotateButtons from './ui/rotate_buttons'
import EditButton from './ui/edit_button'
import DownloadButton from './ui/download_button'
import SortableBar from './ui/sortable_bar'
import CustomPhotoswipeUI from './custom_photoswipe_ui'
import classnames from 'classnames'
import PswpEvents from './photoswipe_events'
import store from 'stores/photoswipe_store'

const propTypes = {
  options: PropTypes.object,
  onClose: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string
}
const defaultProps = {
  options: {},
  onClose: () => {
  },
  id: '',
  className: ''
}

class SmPhotoswipe extends React.Component {
  constructor (props) {
    super(props)
    store.updatePositionUrl = this.props.updatePositionUrl
    this.listener = store.addListener('change', () => this.forceUpdate())
  }

  componentDidMount () {
    if (store.isOpen) {
      this.openPhotoSwipe(this.props)
    }
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (store.isOpen) {
      this.openPhotoSwipe(this.props)
    } else {
      this.closePhotoSwipe(this.props)
    }
  }

  componentWillUnmount () {
    this.closePhotoSwipe()
    this.listener.remove()
  }

  openPhotoSwipe (props) {
    let options = {
      index: store.index,
      history: false,
      shareEl: false,
      fullscreenEl: false,
      loop: false,
      clickToCloseNonZoomable: false,
      tapToClose: false,
      closeElClasses: ['caption', 'top-bar'],
      galleryUID: props.galleryId,
    }
    Object.assign(options, props.options)

    store.currPswpGallery = new Photoswipe(
      this.pswpElement,
      CustomPhotoswipeUI,
      store.galleryItems, options
    )
    new PswpEvents(store, props)

    store.currPswpGallery.updateCurrentItem = function (data) {
      this.currItem = Object.assign(this.currItem, data)
      this.invalidateCurrItems()
      this.updateSize(true)
    }

    store.currPswpGallery.showProgressBar = function() {
      store.currPswpGallery.updateCurrentItem(this.props.loadingImgData)
    }.bind(this)

    store.currPswpGallery.init()
  }

  closePhotoSwipe () {
    if (!store.currPswpGallery) return
    store.currPswpGallery.close()
  }

  render () {
    const { id } = this.props
    let { className } = this.props
    className = classnames(['pswp', className]).trim()
    return (
      <React.Fragment>
        <div
          id={id}
          className={className}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          ref={(node) => {
            this.pswpElement = node
          }}
        >
          <SortableBar  active={ store.isEnabledPositionUpdate() } />
          <div className="pswp__bg"/>
          <div className="pswp__scroll-wrap">
            <div className="pswp__container">
              <div className="pswp__item"/>
              <div className="pswp__item"/>
              <div className="pswp__item"/>
            </div>
            <div className="pswp__ui pswp__ui--hidden">
              <div className="pswp__top-bar">
                <div className="pswp__counter"/>
                <div className="pswp__uploaded-at"/>
                <div className="pswp__control-buttons">
                  <button className="pswp__button pswp__button--close" title="Close (Esc)" />
                  <button className="pswp__button pswp__button--delete" title="Delete" />
                  <RotateButtons degree="90" active={ store.isEnabledRotateControls() } />
                  <EditButton />
                  <DownloadButton currentItem={store.currentItem} />
                  <button className="pswp__button pswp__button--fs" title="Toggle fullscreen" />
                  <button className="pswp__button pswp__button--zoom" title="Zoom in/out"/>
                </div>
                <div className="pswp__preloader">
                  <div className="pswp__preloader__icn">
                    <div className="pswp__preloader__cut">
                      <div className="pswp__preloader__donut"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div className="pswp__share-tooltip"/>
              </div>
              <button
                className="pswp__button pswp__button--arrow--left"
                title="Previous (arrow left)"
              />
              <button
                className="pswp__button pswp__button--arrow--right"
                title="Next (arrow right)"
              />
              <div className="pswp__caption">
                <div className="pswp__caption__center"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(["pswp__album-title", store.isOpen ? '' : 'hidden'])} />
      </React.Fragment>
    )
  }
}

SmPhotoswipe.propTypes = propTypes
SmPhotoswipe.defaultProps = defaultProps

export default SmPhotoswipe
