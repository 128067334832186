const $ = window.$

const callDuplicateMemberAction = (url) => {
  $.ajax({ url: url, method: 'post' })
}

export default class AdminPanel {
  constructor () {
    if ($('body').hasClass('admin_namespace')) {
      $(() => { this.init() })
    }
  }

  init () {
    $('a.perform-duplicate-later').on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      callDuplicateMemberAction($(e.currentTarget).prop('href'))
    })
  }
}
