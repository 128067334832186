import * as React from 'react'
import BaseReactSelect from '../../../base_react_select'

type Location = {
  value: number
  label: string
  url: string
}

type Option = {
  value: number
  label: string
}

interface ElementGroupsSelectorProps {
  options: Option[]
  multi: boolean
  setElementGroups: (element_groups: Option[]) => void
  loadElements: (url: string, element_group_ids: number[]) => void
  element_groups: Option[]
  location: Location | null
}

export default function ElementGroupsSelector(props: ElementGroupsSelectorProps) {
  const { options, multi, setElementGroups, loadElements, element_groups, location } = props

  const onChange = (collection) => {
    setElementGroups(collection)
    if (location)
      loadElements(
        location.url,
        collection.map((obj) => obj.value)
      )
  }

  return (
    <div className="d-flex">
      <div className="element-group-ids-selector-select flex-grow-1">
        <BaseReactSelect
          isMulti={multi}
          value={element_groups}
          options={options}
          onChange={(collection) => onChange(collection)}
          isDisabled={location ? false : true}
        />
      </div>
    </div>
  )
}
