import { connect } from 'react-redux'
import { types } from 'actions/projects/form_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([
  types.LOAD_PROJECT_GROUPS,
  types.LOAD_ADDITIONAL_FORM_FIELDS
])

export default connect((state) => ({ isFetching: loadingSelector(state) } ), {})
