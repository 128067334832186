import React from 'react'
import PropTypes from 'prop-types'

const { I18n } = window

export default function DownloadButton (props) {
  const { currentItem } = props
  if (!currentItem) return null
  if (!currentItem.downloadUrl) return null
  return (
    <div className="pswp__download-button">
      <a className="fa fa-download" title={I18n.t('application.actions.download')} aria-hidden="true" href={currentItem.downloadUrl} />
    </div>
  )
}

DownloadButton.propTypes = {
  currentItem: PropTypes.shape({
    downloadUrl: PropTypes.string,
  }),
}

DownloadButton.defaultProps = {
  currentItem: {},
}
