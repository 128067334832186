class ItemInterface {

  constructor () {
    this.options = {}
  }

  extendSelectOptions = (items) => items

  optionRenderer () {
    return this.renderOption || null
  }
}

export default ItemInterface
