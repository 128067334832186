import { connect } from 'react-redux'
import {
  setSelectedElements,
  resetCreateLocationElementStatusMessages,
  setAvailableLocations,
  createNewLocationElement,
  loadAdditionalFormFieldsLocationElement,
  updateLocationElement,
  types,
} from '../../../../../actions/projects/form_actions'
import { createLoadingSelector } from '../../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([
  types.CREATE_NEW_LOCATION_ELEMENT,
  types.LOAD_ADDITIONAL_FORM_FIELDS_LOCATION_ELEMENT,
  types.UPDATE_LOCATION_ELEMENT,
])

const mapStateToProps = (state) => {
  return {
    element_groups: state.getIn(['projects', 'form', 'element_groups']).toJS() || {},
    options: state.getIn(['projects', 'form', 'elements']).toJS(),
    selected_elements: state.getIn(['projects', 'form', 'selected_elements']).toJS() || {},
    location: state.getIn(['projects', 'form', 'location']) ? state.getIn(['projects', 'form', 'location']).toJS() : null,
    isFetching: loadingSelector(state),
    createLocationElementSuccess: state.getIn(['projects', 'form', 'createLocationElementSuccess']) || null,
    createLocationElementMessage: state.getIn(['projects', 'form', 'createLocationElementMessage']) || null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedElements: (elements) => dispatch(setSelectedElements(elements)),
    resetCreateLocationElementStatusMessages: (data) => dispatch(resetCreateLocationElementStatusMessages(data)),
    setAvailableLocations: (data) => dispatch(setAvailableLocations(data)),
    createNewLocationElement: (data) => dispatch(createNewLocationElement(data)),
    loadAdditionalFormFieldsLocationElement: (data) => dispatch(loadAdditionalFormFieldsLocationElement(data)),
    updateLocationElement: (data, formDataObj) => dispatch(updateLocationElement(data, formDataObj)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
