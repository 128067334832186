import React, { Fragment } from 'react'
import connect from './connect'
import BaseSpinner from 'components/shared/spinner'

class Spinner extends React.Component {
  render () {
    const { isFetching } = this.props
    return (
      <Fragment>
        {isFetching && <BaseSpinner viewType="inline" size="1" />}
      </Fragment>
    )
  }
}

export default connect(Spinner)
