import React from 'react'
import store from 'stores/attachable_links_store'

export default class DeleteControl extends React.Component {
  constructor (props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    store.remove(this.props.index, this.props.item.id)
  }

  render () {
    return (
      <div className="attachable-links-list-delete-control">
        <i className="fa fa-times-circle fa-lg" aria-hidden="true" onClick={this.onClick}></i>
      </div>
    )
  }
}
