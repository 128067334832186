import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import LocationSelector from './location_selector'

function LocationSelectorApp(props) {
  return (
    <Provider store={store}>
      <LocationSelector {...props} />
    </Provider>
  )
}

export default LocationSelectorApp
