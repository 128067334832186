const EVENTS = [
  'beforeChange',
  'afterChange',
  'imageLoadComplete',
  'resize',
  'gettingData',
  'mouseUsed',
  'initialZoomIn',
  'initialZoomInEnd',
  'initialZoomOut',
  'initialZoomOutEnd',
  'parseVerticalMargin',
  'close',
  'unbindEvents',
  'destroy',
  'updateScrollOffset',
  'preventDragEvent',
  'shareLinkClick'
]

export default class PswpEvents {

  constructor (store, props) {
    const pswp = store.currPswpGallery

    EVENTS.forEach((event) => {
      const callback = props[event]
      if (callback || event === 'destroy') {
        const self = this
        pswp.listen(event, function (...args) {
          if (callback) {
            args.unshift(this)
            callback(...args)
          }
          if (event === 'destroy') { self.handleClose(store, props) }
        })
      }
    })

    pswp.listen('gettingData', function(index, item) {
      if (item.w < 1 || item.h < 1) {
        var img = new Image();
        img.onload = function() {
          item.w = this.width
          item.h = this.height
          // reinit Items
          pswp.invalidateCurrItems()
          pswp.updateSize(true)
        }
        // download image
        img.src = item.src
      }
    })

    pswp.listen('afterChange', function () {
      const { uploadedAt, albumTitle } = this.currItem
      if (uploadedAt) { document.querySelector('.pswp__uploaded-at').innerHTML = uploadedAt }
      if (albumTitle) { document.querySelector('.pswp__album-title').innerHTML = (albumTitle || '') }
      store.pswpChanged()
    })
  }

  handleClose (store, { onClose }) {
    store.closePhotoswipe()
    if (onClose) { onClose() }
  }
}
