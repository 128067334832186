import handlers  from './handlers'

const HANDLERS = {
  default: handlers.DefaultHanlder
}

class ConfirmationWindow {
  constructor (mainContainerSelector, elementSelector) {
    $(mainContainerSelector).on('click', elementSelector, function(e, confirmed) {
      if (!confirmed) {
        e.preventDefault()
        e.stopImmediatePropagation()
        let $current = $(e.currentTarget)
        let type = $current.data('handler') || 'default'
        new HANDLERS[type]($current)
      }
    })
  }
}

export default ConfirmationWindow
