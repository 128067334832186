import React from 'react'
import Select from 'components/shared/base_react_select'
import HiddenInputs from 'components/shared/hidden_inputs'
import Interfaces from 'components/shared/selector_interfaces'
import store from 'stores/project_form_store'

class ItemsSelector extends React.Component {

  constructor (props) {
    super(props)
    this.valuesKey  = this.props.name.match(/\[(.*?)\]/)[1]
    this.optionsKey = this.valuesKey + '_options'
    store.items[this.valuesKey]  = this.props.value
    store.items[this.optionsKey] = this.props.options
    this.listener = store.addListener('NewLocationItems', () => this.forceUpdate())

    if (this.valuesKey == 'element_ids') this.itemInterface = new Interfaces.ElementItemInterface()
    else this.itemInterface = new Interfaces.ItemInterface()
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  onChange (ops) {
    store.items[this.valuesKey] = ops
    this.forceUpdate()
  }

  render () {
    return (
      <div>
        <Select
          isMulti={this.props.multi}
          value={store.items[this.valuesKey]}
          options={ this.itemInterface.extendSelectOptions(store.items[this.optionsKey]) }
          optionRenderer={ this.itemInterface.optionRenderer() }
          onChange={ (opt) => this.onChange(opt) }
        />
        <HiddenInputs items={ store.items[this.valuesKey] } name={ this.props.name } />
      </div>
    )
  }
}

export default ItemsSelector
