import React from 'react'
import store from 'stores/photoswipe_store'

class EditButton extends React.Component {

  onClick = (e) => {
    let editUrl = this.editUrl()
    if (editUrl) { window.location.href = editUrl }
  }

  editUrl () {
    return store.currPswpGallery &&
      store.currPswpGallery.currItem &&
      store.currPswpGallery.currItem.editUrl
  }

  render () {
    return null // We disable the Edit button until we have properly working editor
    if (!this.editUrl()) return null
    return (
      <div className="pswp__edit-button">
        <a className="fa fa-edit" title="Edit" aria-hidden="true" onClick={ this.onClick }></a>
      </div>
    )
  }
}

export default EditButton
