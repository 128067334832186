import * as React from 'react'
import FormGroup from '../installers_selector_app/form_group'

const { I18n } = window as any

type CreateServiceCompanyFormProps = {
  addressCountryOptions: { value: string; label: string }[]
  data: {
    company_id: number
    name: string
    address_attributes: {
      line1: string
      line2: string
      zip: string
      city: string
      country: string
    }
  }
  setData: (data: any) => void
  formRef: any
}
export default function CreateServiceCompanyForm(props: CreateServiceCompanyFormProps) {
  const { data, setData, formRef, addressCountryOptions } = props
  return (
    <form className="default-form" action="#" method="POST" ref={formRef}>
      <ul>
        <FormGroup label={I18n.t('activerecord.attributes.vendors/base.name')} name="project_service_company_name">
          <input
            id="project_service_company_name"
            className="form-control"
            type="text"
            required
            name="name"
            value={data.name}
            onChange={(e) =>
              setData({
                ...data,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <li>
          <h5 className="mb-3">{I18n.t('activerecord.attributes.location.address')}</h5>
        </li>
        <FormGroup
          label={I18n.t('activerecord.attributes.address.line1')}
          name="project_service_company_address_line_1"
        >
          <input
            id="project_service_company_address_line_1"
            className="form-control"
            type="text"
            name="address_attributes[line1]"
            value={data.address_attributes.line1}
            onChange={(e) =>
              setData({
                ...data,
                address_attributes: {
                  ...data.address_attributes,
                  line1: e.target.value,
                },
              })
            }
          />
        </FormGroup>
        <FormGroup
          label={I18n.t('activerecord.attributes.address.line2')}
          name="project_service_company_address_line_2"
        >
          <input
            id="project_service_company_address_line_2"
            className="form-control"
            type="text"
            name="address_attributes[line2]"
            value={data.address_attributes.line2}
            onChange={(e) =>
              setData({
                ...data,
                address_attributes: {
                  ...data.address_attributes,
                  line2: e.target.value,
                },
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.address.zip')} name="project_service_company_address_zip">
          <input
            id="project_service_company_address_zip"
            className="form-control"
            type="text"
            name="address_attributes[zip]"
            value={data.address_attributes.zip}
            onChange={(e) =>
              setData({
                ...data,
                address_attributes: {
                  ...data.address_attributes,
                  zip: e.target.value,
                },
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.address.city')} name="project_service_company_address_city">
          <input
            id="project_service_company_address_city"
            className="form-control"
            type="text"
            name="address_attributes[city]"
            value={data.address_attributes.city}
            onChange={(e) =>
              setData({
                ...data,
                address_attributes: {
                  ...data.address_attributes,
                  city: e.target.value,
                },
              })
            }
          />
        </FormGroup>
        <FormGroup
          label={I18n.t('activerecord.attributes.address.country')}
          name="project_service_company_address_country"
        >
          <select
            id="project_service_company_address_country"
            className="form-control"
            name="address_attributes[country]"
            value={data.address_attributes.country}
            onChange={(e) =>
              setData({
                ...data,
                address_attributes: {
                  ...data.address_attributes,
                  country: e.target.value,
                },
              })
            }
          >
            {addressCountryOptions.map((option) => (
              <option key={option.value} value={option.value} disabled={option.value === null}>
                {option.label}
              </option>
            ))}
          </select>
        </FormGroup>
      </ul>
    </form>
  )
}
