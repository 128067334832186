import Confirmation from '../libs/confirmation_window'
const $ = window.$

class Bootstrap {
  constructor () {
    $(() => {
      if ($('[data-toggle="popover"]').popover) {
        $('[data-toggle="popover"]').popover({
          html: true,
          // do not use sanitizer, default behaviour for Bootstrap < 4.3
          sanitizeFn: content => content
        })
      }
      new Confirmation('body', '.wait-confirm')

      // Allow to use data-attribute to make links to tabs
      $(document).on('click', '[data-scroll-to-and-open=tab]', function(e) {
        e.preventDefault()
        e.stopPropagation()
        var href = e.target.getAttribute('href')
        $(`.nav-tabs a[href="${href}"]`).tab('show').get(0).scrollIntoView()
      })
    })
  }
}

export default Bootstrap
