import React from 'react'
import store from 'stores/photoswipe_store'

class RotateButtons extends React.Component {

  turnLeft () {
    store.rotateCurrentItem(this.props.degree, 0).catch(error => this.handleError(error))
  }

  turnRight () {
    store.rotateCurrentItem(this.props.degree, 1).catch(error => this.handleError(error))
  }

  handleError (error) {
    store.currPswpGallery.close()
    window.location.reload(true)
  }

  render () {
    if (!this.props.active) return null
    return (
      <div className="pswp__rotate-buttons">
        <a className="fa fa-undo" title="Turn left" aria-hidden="true" onClick={ () => this.turnLeft() }></a>
        <a className="fa fa-repeat" title="Turn right" aria-hidden="true" onClick={ () => this.turnRight() }></a>
      </div>
    )
  }
}

export default RotateButtons
