import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/shared/base_react_select'
import FormGroup from './form_group'

const { I18n } = window

function CreateInstallerForm({ availableVendors, data, setData, formRef }) {
  return (
    <form className="default-form" action="#" method="POST" ref={formRef}>
      <ul>
        <FormGroup label={I18n.t('activerecord.models.company.one')} name="company_id">
          <Select
            required
            options={availableVendors}
            value={availableVendors.filter((o) => data['company_id'] == o.value)}
            onChange={(v) =>
              setData({
                ...data,
                copmany_id: v,
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.users/base.name')} name="users_installer_name">
          <input
            id="users_installer_name"
            className="form-control"
            type="text"
            name="name"
            required
            value={data['name']}
            onChange={(e) =>
              setData({
                ...data,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.users/base.email')} name="users_installer_email">
          <input
            id="users_installer_email"
            className="form-control"
            type="email"
            name="email"
            required
            value={data['email']}
            onChange={(e) =>
              setData({
                ...data,
                email: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.users/base.password')} name="password">
          <input
            className="form-control"
            type="password"
            name="password"
            required={!(data['generate_random_password'] || data['send_invitation_email'])}
            disabled={data['generate_random_password'] || data['send_invitation_email']}
            value={data['password']}
            onChange={(e) =>
              setData({
                ...data,
                password: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup
          label={I18n.t('activerecord.attributes.users/base.generate_random_password')}
          name="generate_random_password"
          type="checkbox"
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="generate_random_password"
            name="generate_random_password"
            value={1}
            disabled={data['send_invitation_email']}
            checked={data['send_invitation_email'] ? false : data['generate_random_password']}
            onChange={(e) =>
              setData({
                ...data,
                generate_random_password: e.target.checked,
              })
            }
          />
        </FormGroup>
        <FormGroup
          label={I18n.t('activerecord.attributes.users/base.send_invitation_email')}
          name="send_invitation_email"
          type="checkbox"
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="send_invitation_email"
            name="send_invitation_email"
            value="1"
            checked={data['send_invitation_email']}
            onChange={(e) =>
              setData({
                ...data,
                send_invitation_email: e.target.checked,
              })
            }
          />
        </FormGroup>
      </ul>
    </form>
  )
}

CreateInstallerForm.propTypes = {
  availableVendors: PropTypes.array.isRequired,
}

export default CreateInstallerForm
