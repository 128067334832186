import _ from 'underscore'

class InjectView {
  constructor (views) {
    let view = views
    const id = document.getElementById('route-info').dataset.route
    if (id) {
      const data = id.split('-')
      _.each(data, (p) => {
        if (view) { view = view[p] }
      })
      if (view) { view() }
    }
  }
}

export default InjectView
