class NotificationsBlocker {

  constructor () {
    this._catchThemes = []
    this._blockedNotifications = {}
  }

  addCatchFor = (theme) => this._catchThemes.push(theme)

  actualFor = (theme) => this._catchThemes.includes(theme)

  put = (theme, notifAction) => {
    this._blockedNotifications[theme] = notifAction
  }

  isBlocked = (theme) => !!this._blockedNotifications[theme]

  call = (theme) => this.isBlocked() && this._blockedNotifications[theme]()
}

export default new NotificationsBlocker()
