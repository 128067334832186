import store from 'store'
import React from 'react'
import { Provider } from 'react-redux'
import { initUploader } from 'actions/uploader_actions'
import Uploader from './uploader'

const UploaderV2 = (props) => {

  store.dispatch(initUploader(props.buttonId, props))

  return (
    <Provider store={store}>
      <Uploader buttonId={props.buttonId} />
    </Provider>
  )
}

export default UploaderV2
