import React from 'react'
import { isEqual } from 'lodash'
import Select from 'components/shared/base_react_select'
import connect from './connect'

const { $ } = window

class Selector extends React.Component {
  componentDidMount () {
    this.$fieldsContainer = $('ul.form-fields')
    this.$submitBtn = $('input[type=submit]', this.$fieldsContainer.closest('form'))
    if (!this.props.groups.find(group => group.selected)) {
      this.changeSubmitBtnDisabledState(true)
    }
    this.autoSelectIfOne(this.props.groups)
  }

  componentDidUpdate (prevProps) {
    if (isEqual(prevProps, this.props)) { return }

    this.changeSubmitBtnDisabledState(this.props.isFetching)
    this.updateFormFields()
    this.autoSelectIfOne(this.props.groups)
  }

  get groupId () { return this.current && this.current.value }

  changeSubmitBtnDisabledState = (isDisabled) => this.$submitBtn.prop('disabled', isDisabled)


  onChange = (opt) => {
    this.loadAdditionalFormFields(opt.value)
  }


  loadAdditionalFormFields = (groupId) => {
    const { locationId, disabledFields, loadAdditionalFormFields, navOrderId, jobNumber } = this.props

    const data = { group_id: groupId }
    if (locationId) { data['location_id'] = locationId }
    if (disabledFields) { data['disabled_fields'] = disabledFields }
    if (navOrderId) { data['nav_order_id'] = navOrderId }
    if (jobNumber) { data['job_number'] = jobNumber }

    loadAdditionalFormFields(data)
  }

  autoSelectIfOne (groups) {
    if (!this.current.value && groups.length == 1) {
      this.loadAdditionalFormFields(groups[0].value)
    }
  }

  updateFormFields () {
    this.$fieldsContainer.html(this.props.fieldsHtml)
    if (window.ReactRailsUJS) { window.ReactRailsUJS.mountComponents(this.$fieldsContainer.get(0)) }
  }

  render () {
    const { groups } = this.props
    this.current = groups.find(group => group.selected ) || {}
    return (
      <div>
        <Select
          value={this.current}
          hasValue={groups.length == 0}
          options={groups}
          onChange={this.onChange}
        />
        <input type="hidden" name="group_id" value={this.groupId || ''} />
      </div>
    )
  }
}

export default connect(Selector)
