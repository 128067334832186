import ajax from 'axios'

const JOB_STATUS_TIMEOUT = 2000

class JobStatus {
  constructor(jobId) {
    this.jobId = jobId
  }

  waitForComplete() {
    return new Promise((resolve, reject) => {
      const poll = (makeRequest) => {
        makeRequest().then((response) => {
          status = response.data.status
          if (status == 'completed') {
            resolve(status)
          } else if (status == 'failed') {
            reject(I18n.t('job_tracker.messages.failed'))
          } else {
            return setTimeout(() => poll(makeRequest), JOB_STATUS_TIMEOUT)
          }
        }).catch((message) => {
          reject(message)
        })
      }

      const params = { url: this._url(), method: 'get' }
      poll(() => ajax(params) )
    })
  }

  fetch() {
    const ajaxParams = { url: this._url(), method: 'get' }
    return new Promise((resolve, reject) => {
      ajax(ajaxParams).then((response) => {
        resolve(response.data.status)
      })
    })
  }

  _url() {
    return `/job_statuses/${this.jobId}.json`
  }
}
export default JobStatus
