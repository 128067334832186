import * as React from 'react'
import FormGroup from '../../installers_selector_app/form_group'
import Select from '../../../../shared/base_react_select'

const { I18n } = window as any

type Option = {
  value: string
  label: string
}

function CreateElementForm({ data, setData, formRef, elementsTemplates, location, element_groups }) {
  const transformeElementTemplateToOption = (elementTemplate): Option => {
    return { value: elementTemplate.id, label: elementTemplate.name }
  }

  return (
    <form className="default-form" action="#" method="POST" ref={formRef}>
      <ul className='location-element-popup'>
        <FormGroup label={I18n.t('activerecord.attributes.elements/base_element.template')}>
          <Select
            options={elementsTemplates.map(transformeElementTemplateToOption)}
            id="location_element_template"
            onChange={(e) => {
              setData({
                ...data,
                template_id: e.value,
                location_id: location.value,
                group_id: element_groups[0].value,
              })
            }}
            isDisabled={!(location && element_groups.length === 1)}
          />
        </FormGroup>
      </ul>
    </form>
  )
}

export default CreateElementForm
