import React from 'react'
import Select from 'components/shared/base_react_select'

class ServiceCompanySelector extends React.Component {

  constructor (props) {
    super(props)
    this.state = { serviceCompany: (props.options.find(o => o.value === props.value) || {}) }
  }

  onChange = (opt) => this.setState({ serviceCompany: opt })

  render () {
    const { serviceCompany } = this.state

    return (
      <div>
        <Select
          value={serviceCompany}
          options={this.props.options}
          onChange={ (opt) => this.onChange(opt) }
        />
        <input type="hidden" value={serviceCompany?.value} name={this.props.name} />
      </div>
    )
  }
}

export default ServiceCompanySelector
