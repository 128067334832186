import * as React from 'react'
import { useState, useEffect, useRef, useCallback } from 'react'
import SmModalWindow from '../../../modal_window'
import Spinner from '../../../spinner'
import CreateElementForm from './create_element_form'

const { I18n, $ } = window

function parseNestedKeys(data) {
  const result = {}

  for (const key in data) {
    const keys = key.split(/[\[\]]+/).filter(Boolean)
    let current = result
    keys.forEach((subKey, index) => {
      if (index === keys.length - 1) {
        if (subKey === '' && Array.isArray(current)) {
          current.push(data[key])
        } else {
          current[subKey] = data[key] === '' && key.endsWith('[]') ? [] : data[key]
        }
      } else {
        if (!current[subKey]) {
          if (keys[index + 1] === '' || isNaN(keys[index + 1])) {
            current[subKey] = {}
          } else {
            current[subKey] = []
          }
        }
        current = current[subKey]
      }
    })
  }

  return result
}

function CreateElementButton({
  createNewLocationElement,
  loadAdditionalFormFieldsLocationElement,
  isFetching,
  createLocationElementSuccess,
  createLocationElementMessage,
  resetCreateLocationElementStatusMessages,
  buttonTitle,
  windowTitle,
  elementsTemplates,
  location,
  element_groups,
  updateLocationElement,
}) {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [data, setData] = useState({
    location_id: '',
    group_id: '',
    template_id: '',
    element_id: null,
  })
  const initialUlContent = useRef(null)

  useEffect(() => {
    const ulElement = $('ul.location-element-popup').get(0)
    if (dialogOpened) {
      if (ulElement) {
        initialUlContent.current = ulElement.innerHTML
      }
    } else {
      resetCreateLocationElementStatusMessages()

      setData({
        ...data,
        element_id: null,
      })

      if (ulElement && initialUlContent.current !== null) {
        ulElement.innerHTML = initialUlContent.current
        window.ReactRailsUJS.mountComponents(ulElement)
      }
    }
  }, [dialogOpened])

  const formRef = useRef(null)

  const handleSave = () => {
    const f = formRef.current
    const valid = f.checkValidity()
    if (!valid) {
      f.reportValidity()
    } else {
      createNewLocationElement(data).then((result) => {
        setData({
          ...data,
          element_id: result.data.element.id,
        })
        loadAdditionalFormFieldsLocationElement(result.data.element.id).then((result) => {
          window.ReactRailsUJS.mountComponents($('ul.location-element-popup').html(result.data.form_fields).get(0))
        })
      })
    }
    return valid
  }

  const handleUpdate = () => {
    const form = formRef.current
    const valid = form.checkValidity()
    if (!valid) {
      form.reportValidity()
    } else {
      const formData = new FormData(form)
      const formDataObj = {}
      formData.forEach((value, key) => {
        if (!formDataObj[key]) {
          formDataObj[key] = value
        } else if (Array.isArray(formDataObj[key])) {
          formDataObj[key].push(value)
        } else {
          formDataObj[key] = [formDataObj[key], value]
        }
      })
      updateLocationElement(data, parseNestedKeys(formDataObj))
    }
    return valid
  }

  const modalContent = (
    <>
      { createLocationElementMessage && <div className={`text-${createLocationElementSuccess ? 'success' : 'danger'} text-center`}>{createLocationElementMessage}</div>}
      {!createLocationElementSuccess && (
        <div className="text-left">
          {dialogOpened && (
            <CreateElementForm
              data={data}
              setData={setData}
              formRef={formRef}
              elementsTemplates={elementsTemplates}
              location={location}
              element_groups={element_groups}
            />
          )}
        </div>
      )}
    </>
  )
  const modalButtons = (
    <div className="buttons-container">
      <button
        className="btn btn-primary"
        type="button"
        disabled={
          isFetching || createLocationElementSuccess || !(location && element_groups.length === 1)
        }
        onClick={data.element_id ? handleUpdate : handleSave}
      >
        {isFetching ? <Spinner className="ml-3 mr-3" viewType="inline" size="1" /> : I18n.t('application.actions.save')}
      </button>
    </div>
  )
  return (
    <>
      <button
        className="btn btn-outline-secondary w-100"
        type="button"
        disabled={!(location && element_groups.length === 1)}
        onClick={() => setDialogOpened(true)}
      >
        <i className="fa fa-icon fa-plus mr-2" />
        {buttonTitle}
      </button>
      <SmModalWindow
        title={windowTitle}
        visible={dialogOpened}
        onClose={(e) => {
          setDialogOpened(false)
        }}
        content={modalContent}
        buttons={modalButtons}
        windowID="new_element_popup"
      />
    </>
  )
}

export default CreateElementButton
