import * as React from 'react'
import FormGroup from '../installers_selector_app/form_group'

const { I18n } = window as any

function CreateLocationForm({ data, setData, formRef }) {
  return (
    <form className="default-form" action="#" method="POST" ref={formRef}>
      <ul>
        <FormGroup label={I18n.t('activerecord.attributes.location.name')} name="project_location_name">
          <input
            id="project_location_name"
            className="form-control"
            type="text"
            required
            name="name"
            value={data['name']}
            onChange={(e) =>
              setData({
                ...data,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup label={I18n.t('activerecord.attributes.location.number')} name="project_location_number">
          <input
            id="project_location_number"
            className="form-control"
            type="text"
            name="number"
            value={data['number']}
            onChange={(e) =>
              setData({
                ...data,
                number: e.target.value,
              })
            }
          />
        </FormGroup>
      </ul>
    </form>
  )
}

export default CreateLocationForm
