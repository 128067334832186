import React from 'react'
import ReactDOM from 'react-dom'
import PhotoswipeLink from './photoswipe_link'

class PhotoswipeImageLink extends React.Component {
  constructor (props) {
    super(props)
    this.state = { imgVisible: !props.waitImgLoad }
  }

  onLoad = () => {
    if (!this.state.imgVisible) { this.setState({ imgVisible: true }) }
  }

  render () {
    const {
      previewWidth, previewHeight, linkText, imgSrc,
    } = this.props
    let styles = {}

    if (!this.state.imgVisible) { styles.display = 'none' } else { styles = {} }

    return (
      <PhotoswipeLink {...this.props}>
        <img src={imgSrc} alt={this.props.img_alt} width={previewWidth} height={previewHeight} onLoad={this.onLoad} style={styles} />
        &nbsp;
        {linkText}
      </PhotoswipeLink>
    )
  }
}

// Jquery bridge for above component, so it could be used within old code
class PhotoswipeImageLinkJquery {
  constructor (parentElement, props) {
    this.parentElement = parentElement
    this.props = props
    this.rootClass = 'preview-root'
    this.rootSelector = `.${this.rootClass}`
  }

  render () {
    this.parentElement.find(this.rootSelector).each(function () {
      ReactDOM.unmountComponentAtNode(this)
    })
    this.parentElement.find(this.rootSelector).remove()
    this.parentElement.append(`<span class='${this.rootClass}'></span>`)
    ReactDOM.render(<PhotoswipeImageLink {...this.props} />, this.parentElement.find(`${this.rootSelector}`).get(0))
  }
}
window.PhotoswipeImageLinkJquery = PhotoswipeImageLinkJquery

export default PhotoswipeImageLink
