import CableEmitter from 'emitters/cable_emitter'
import notificationBlocker from 'libs/notifications_blocker'

class CableConsumer {
  constructor () {
    if (!window.ActionCable) { return null }
    this.cable = window.ActionCable.createConsumer()
    this.subscribeMyFiles()
    this.subscribeNotifications()
    this.subscribeReportPreview()
    // TODO (atanych): later we might create more clever subscribe engine
  }

  subscribeMyFiles () {
    this.cable.subscriptions.create('MyFilesChannel', {
      received: (data) => {
        CableEmitter.emit(`MY_FILES.${data.event}`, data.payload)
      },
    })
  }

  subscribeNotifications () {
    this.cable.subscriptions.create('NotificationsChannel', {
      received: (data) => {
        const { type, message, theme } = data.payload
        const showMessage = () => window.toastr[type](message)
        if (notificationBlocker.actualFor(theme)) { notificationBlocker.put(theme, showMessage) }
        else { showMessage() }
      },
    })
  }

  subscribeReportPreview () {
    this.cable.subscriptions.create('ReportPreviewChannel', {
      received: (data) => {
        CableEmitter.emit(`REPORT_PREVIEW_CHANNEL.${data.event}`, data.payload)
      },
    })
  }


}

export default CableConsumer
