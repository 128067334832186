import React from 'react'
import Item from './item'

export default function List (props) {
  return (
    <div className=".attachable-links-list">
      {props.links.map((item, index) => <Item data={item} index={index} key={item.key} />)}
    </div>
  )
}
