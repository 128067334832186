import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import LocationOverviewInput from './location_overview_input'

type LocationOverviewInputAppProps = {
  imageUrl: string
  overviewData: any
  isAdmin: boolean
  locationAlbums: Array<any>
  locationElements: Array<any>
  elementsGroups: Array<any>
  locationId: number
}

function LocationOverviewInputApp(props: LocationOverviewInputAppProps) {
  const { imageUrl, overviewData, isAdmin, locationAlbums, locationElements, elementsGroups, locationId } = props
  return (
    <Provider store={store}>
      <LocationOverviewInput
        imageUrl={imageUrl}
        overviewData={overviewData}
        isAdmin={isAdmin}
        locationAlbums={locationAlbums}
        locationElements={locationElements}
        elementsGroups={elementsGroups}
        locationId={locationId}
      />
    </Provider>
  )
}

export default LocationOverviewInputApp
