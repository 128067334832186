import { connect } from 'react-redux'
import { setElementGroups, loadElements } from '../../../../../actions/projects/form_actions'

const mapStateToProps = (state) => {
  return {
    element_groups: state.getIn(['projects', 'form', 'element_groups']).toJS() || {},
    location: state.getIn(['projects', 'form', 'location']) ? state.getIn(['projects', 'form', 'location']).toJS() : null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setElementGroups: (element_groups) => dispatch(setElementGroups(element_groups)),
    loadElements: (url, element_group_ids) => dispatch(loadElements(url, element_group_ids)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
