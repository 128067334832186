import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import SmModalWindow from '../../modal_window'
import Spinner from '../../spinner'
import CreateServiceCompanyForm from './create_service_company_form'

const { I18n } = window as any

type CreateServiceCompanyButtonProps = {
  companyId: number
  addressCountryOptions: { value: string; label: string }[]
  isFetching: boolean
  createNewServiceCompany: (data: any) => void
  createServiceCompanyError: string
  createServiceCompanySuccess: string
  resetCreateServiceCompanyStatusMessages: () => void
  buttonTitle: string
  windowTitle: string
}
export default function CreateServiceCompanyButton(props: CreateServiceCompanyButtonProps) {
  const {
    companyId,
    addressCountryOptions,
    isFetching,
    createNewServiceCompany,
    createServiceCompanyError,
    createServiceCompanySuccess,
    resetCreateServiceCompanyStatusMessages,
    buttonTitle,
    windowTitle,
  } = props

  const [dialogOpened, setDialogOpened] = useState(false)
  const [data, setData] = useState({
    company_id: companyId,
    name: '',
    address_attributes: {
      line1: '',
      line2: '',
      zip: '',
      city: '',
      country: 'DK',
    }
  })

  useEffect(() => {
    if (dialogOpened === false) {
      resetCreateServiceCompanyStatusMessages()
    }
  }, [dialogOpened])

  const formRef = useRef(null)

  const handleSave = () => {
    const f = formRef.current
    const valid = f.checkValidity()
    if (!valid) {
      f.reportValidity()
    } else {
      createNewServiceCompany(data)
    }
    return valid
  }

  const modalContent = (
    <>
      {createServiceCompanyError && <div className="text-danger text-center">{createServiceCompanyError}</div>}
      {createServiceCompanySuccess && <div className="text-success text-center">{createServiceCompanySuccess}</div>}
      {!createServiceCompanyError && !createServiceCompanySuccess && (
        <div className="text-left">
          <CreateServiceCompanyForm data={data} setData={setData} formRef={formRef} addressCountryOptions={addressCountryOptions} />
        </div>
      )}
    </>
  )
  const modalButtons = (
    <div className="new-service-company-popup-actions buttons-container">
      <button
        className="btn btn-primary"
        type="button"
        disabled={isFetching || !!createServiceCompanyError || !!createServiceCompanySuccess}
        onClick={handleSave}
      >
        {isFetching ? <Spinner className="ml-3 mr-3" viewType="inline" size="1" /> : I18n.t('application.actions.save')}
      </button>
    </div>
  )
  return (
    <>
      <button className="btn btn-outline-secondary w-100" type="button" onClick={() => setDialogOpened(true)}>
        <i className="fa fa-icon fa-plus mr-2" />
        {buttonTitle}
      </button>
      <SmModalWindow
        title={windowTitle}
        visible={dialogOpened}
        onClose={(e) => {
          setDialogOpened(false)
        }}
        content={modalContent}
        buttons={modalButtons}
        windowID="new_service_company_popup"
      />
    </>
  )
}
