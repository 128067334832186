import React from 'react'
import PropTypes from 'prop-types'
import store from 'stores/attachable_links_store'
import DeleteControl from './delete_control'
import classNames from 'classnames'

const propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

const defaultProps = {
  url: '',
  title: '',
  description: '',
}

function HiddenFieldBuilder({ name, value }) {
  if (!value) {
    return null
  }
  return <input type="hidden" name={name} value={value} />
}

class Item extends React.Component {
  genNameFor(fieldName) {
    return `${store.attrsBaseName}[${this.props.index}][${fieldName}]`
  }

  render() {
    if (this.props.data._destroy) {
      return (
        <div>
          <HiddenFieldBuilder name={this.genNameFor('id')} value={this.props.data.id} />
          <HiddenFieldBuilder name={this.genNameFor('_destroy')} value={this.props.data._destroy} />
        </div>
      )
    } else {
      return (
        <div className={classNames('link-item', 'attachable-links-list-item')}>
          <HiddenFieldBuilder name={this.genNameFor('id')} value={this.props.data.id} />
          <input
            type="text"
            name={this.genNameFor('url')}
            placeholder={store.placeholders.url}
            defaultValue={this.props.data.url}
            className={classNames('form-control', 'attachable-links-list-item-url')}
          />
          <DeleteControl item={this.props.data} index={this.props.index} />
          <input
            type="text"
            name={this.genNameFor('title')}
            placeholder={store.placeholders.title}
            defaultValue={this.props.data.title}
            className="form-control"
          />
          <textarea
            name={this.genNameFor('description')}
            placeholder={store.placeholders.description}
            defaultValue={this.props.data.description}
            className="form-control"
          ></textarea>
        </div>
      )
    }
  }
}

Item.propTypes = propTypes
Item.defaultProps = defaultProps

export default Item
