import InjectView from './inject_view'
import AdminPanel from './admin_panel'
import Bootstrap from './bootstrap'
import WaitForJob from './wait_for_job'
import CableConsumer from './cable_consumer'
import InitDatatable from './init_datatable'

export  { InjectView }

export default {
  AdminPanel,
  Bootstrap,
  CableConsumer,
  InitDatatable,
  WaitForJob,
}
