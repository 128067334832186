import { connect } from 'react-redux'
import { Map } from 'immutable'
import {
  setAvailableInstallers,
  setAvailableVendors,
  setVendors,
  createNewServiceCompany,
  resetCreateServiceCompanyStatusMessages,
  types,
} from '../../../../actions/projects/form_actions'
import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.CREATE_NEW_INSTALLER])

export default connect(
  (state: Map<string, any>) => ({
    availableInstallers: state.getIn(['projects', 'form', 'availableInstallers']).toJS(),
    availableVendors: state.getIn(['projects', 'form', 'availableVendors']).toJS(),
    vendors: state.getIn(['projects', 'form', 'vendors']).toJS(),
    isFetching: loadingSelector(state),
    createServiceCompanyError: state.getIn(['projects', 'form', 'createServiceCompany', 'error']),
    createServiceCompanySuccess: state.getIn(['projects', 'form', 'createServiceCompany', 'success']),
  }),
  {
    setAvailableInstallers,
    setAvailableVendors,
    setVendors,
    createNewServiceCompany,
    resetCreateServiceCompanyStatusMessages,
  }
)
