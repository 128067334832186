import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import DeleteButton from './ui/delete_button.js'

class CustomPhotoswipeUI extends PhotoSwipeUI_Default {
  constructor(pswp, framework) {
    super(...arguments)
    this.bindEvents = this.bindEvents.bind(this)
    this.unbindEvents = this.unbindEvents.bind(this)
    this.onControlsTap = this.onControlsTap.bind(this)
    this.onAfterChange = this.onAfterChange.bind(this)

    this.framework = framework
    this.pswp = pswp
    this.controls = this.framework.getChildByClass(this.pswp.scrollWrap, "pswp__ui")
    this.pswp.listen("bindEvents", this.bindEvents)
    this.pswp.listen("unbindEvents", this.unbindEvents)
    this.initButtons()
  }

  initButtons() {
    this.buttons = []
    const classes = [DeleteButton]
    return classes.forEach(klass => {
      const button = this.controls.querySelector(`.${klass.CLASS_NAME}`)
      if (button) { return this.buttons.push(new klass(this.pswp, this.framework, button)) }
    });
  }

  bindEvents() {
    this.framework.bind(this.controls, "pswpTap", this.onControlsTap)
    this.pswp.listen("afterChange", this.onAfterChange)
    // need to trigger buttons update after UI has been initialized, because
    // `afterChange` event is not triggered for the first item
    this.onAfterChange()
  }

  unbindEvents() {
    this.framework.unbind(this.controls, "pswpTap", this.onControlsTap)
  }

  onControlsTap(event) {
    let button
    if (!(button = _.find(this.buttons, b => b.element === event.target))) { return }
    event.stopPropagation()
    return button.onClick()
  }

  onAfterChange() { return this.buttons.forEach(b => b.onAfterChange()) }
}

export default CustomPhotoswipeUI
