const $ = window.$

class InitDatatable {
  constructor() {
    if ($('body').hasClass('active_admin')) {
      // do not initialize datatables on admin pages
      return
    }

    $.fn.dataTable.ext.order['dom-text-input'] = function ( settings, col ) {
      return this.api().column( col, {order:'index'} ).nodes().map( ( td, i ) => {
        const input = $('input', td)
        return input.length > 0 ?  input.val() : $(td).html()
      })
    }

    $("input.search").each(function() {
      const groupFile = $(this).parent().find('.group-file')
      // append .search-reset button
      if ($(this).parent().find('.search-reset').length === 0) {
        $(this).parent().prepend('<div class="search-wrapper"></div>')
        $(this).parent().find('.search-wrapper').append(this)
        $('<div class="search-reset"></div>').insertAfter($(this))
        if (groupFile.length > 0) {
          groupFile.insertBefore($(this).parent())
        }
      }
      const inputValue = $(this).val()
      $(this).parent().find('.search-reset').toggle(inputValue !== '')
    })

    $("input.search").keyup(function() {
      if ($(this).val().length !==0) {
        $(this).parent().find(".search-reset").show()
      } else {
        $(this).parent().find(".search-reset").hide()
      }
    })

    $('input.search').keydown(function(e) {
      if (e.keyCode === 27) {
        $(this).val("").trigger('change')
        $(this).parent().find(".search-reset").hide()
      }
    })

    $(".search-reset").click(function(e) {
      $(this).parent().find(".search-reset").hide()
      $(this).parent().find("input.search").val("").trigger('change')
    });
  }
}

export default InitDatatable
