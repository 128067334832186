import React from 'react'

const $ = window.$

function ModalHeader (props) {
  return (
    <div className="modal-header">
      <h5 className="modal-title" id={ props.titleId }>{ props.title }</h5>
      <button className="close" type="button" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

function ModalBody (props) {
  return (
    <div className="modal-body">{ props.content }</div>
  )
}

function ModalFooter (props) {
  return (
    <div className="modal-footer">{ props.buttons }</div>
  )
}

class SmModalWindow extends React.Component {

  constructor (props) {
    super(props)
  }

  checkVisibility = () => {
    if(this.props.visible === undefined) { return }

    const modalWindow = $(`#${this.props.windowID}`)

    if (!modalWindow) { return }
    else {
      modalWindow.on('hide.bs.modal', (e) => {
        if (this.props.onClose) { this.props.onClose() }
      })
    }

    if (this.props.visible) { modalWindow.modal('show') }
    else { modalWindow.modal('hide') }
  }

  componentDidUpdate () {
    this.checkVisibility()
  }

  componentDidMount () {
    this.checkVisibility()
  }

  render () {
    return (
      <div className="modal" id={ this.props.windowID } tabIndex="-1" role="dialog" aria-labelledby={ this.props.title_id } aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <ModalHeader title={ this.props.title } titleId={ this.props.titleId } />
            <ModalBody content={ this.props.content} />
            <ModalFooter buttons={ this.props.buttons }/>
          </div>
        </div>
      </div>
    )
  }
}

export default SmModalWindow
