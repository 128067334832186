import JobStatus from 'libs/job_status'

class WaitForJob {
  constructor() {
    const container = document.getElementById('wait-for-job-and-reload')
    if (container) {
      let jobStatus = new JobStatus(container.dataset.jobId)
      jobStatus.waitForComplete()
        .then( status => window.location.reload() )
        .catch( message => alert(message) )
    }
  }
}

export default WaitForJob
