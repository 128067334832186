import * as React from 'react'

type FormGroupProps = {
  name: string
  label: string
  children: React.ReactNode
  type?: 'text' | 'checkbox'
}

function FormGroup({ name, label, children, type = 'text' }: FormGroupProps) {
  return (
    <li className="form-group">
      {type !== 'checkbox' && (
        <>
          <label className="label" htmlFor={name}>
            {label}
          </label>
          <div className="default-form-input-wrapper">{children}</div>
        </>
      )}
      {type === 'checkbox' && (
        <div className="form-check">
          <label className="label" htmlFor={name}>
            {children}
            {label}
          </label>
        </div>
      )}
    </li>
  )
}

export default FormGroup
