import React, { useState } from 'react'
import Select, { components } from 'react-select'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

export function arrayMove(array, from, to) {
  array = array.slice()
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
  return array
}

export const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableSelect = SortableContainer(Select)

function SortableSelectInput({ collection, value, name }) {
  const [selectedOptions, setSelectedOptions] = useState(value || [])
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedOptions, oldIndex, newIndex)
    setSelectedOptions(newValue)
  }
  return (
    <>
      <SortableSelect
        classNamePrefix = 'Select'
        className = 'Select'
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        value={selectedOptions.map((selected) => collection.find((o) => o.value === selected))}
        onChange={(opts) => setSelectedOptions(opts === null ? [] : opts.map((o) => o.value))}
        options={collection}
        components={{
          MultiValue: SortableMultiValue,
        }}
        closeMenuOnSelect={false}
      />
      {selectedOptions.length === 0 && <input type="hidden" name={name} />}
      {selectedOptions.map((val) => (
        <input type="hidden" name={name} value={val} key={val} />
      ))}
    </>
  )
}

export default SortableSelectInput
