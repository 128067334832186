import { connect } from 'react-redux'
import {
  createNewLocation,
  resetCreateLocationStatusMessages,
  setAvailableLocations,
  setLocation,
  types,
  loadElements,
} from '../../../../actions/projects/form_actions'
import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.CREATE_NEW_LOCATION])

export default connect(
  (state) => ({
    createLocationError: state.getIn(['projects', 'form', 'createLocation', 'error']),
    createLocationSuccess: state.getIn(['projects', 'form', 'createLocation', 'success']),
    availableLocations: state.getIn(['projects', 'form', 'availableLocations']).toJS(),
    location: state.getIn(['projects', 'form', 'location'])?.toJS(),
    isFetching: loadingSelector(state),
  }),
  {
    createNewLocation,
    resetCreateLocationStatusMessages,
    setAvailableLocations,
    setLocation,
    loadElements,
  }
)
