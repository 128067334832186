import { EventEmitter } from 'fbemitter'
import uniqueid from 'uniqueid'

const uniqKey = uniqueid('link_')

class AttachableLinksStore extends EventEmitter {
  constructor () {
    super()
    this.links = []
    this.placeholders = {}
  }

  init (links, placeholders, attrsBaseName) {
    this.links = links
    this.placeholders = placeholders
    this.attrsBaseName = attrsBaseName
  }

  add (item) {
    item['key'] = item.id || uniqKey()
    this.links.push(item)
    this.update()
  }

  remove (index, itemPersisted) {
    if (itemPersisted)
      this.links[index]['_destroy'] = '1'
    else
      this.links = _.without(this.links, this.links[index])

    this.update()
  }

  update () {
    this.emit('change')
  }
}

export default new AttachableLinksStore()
