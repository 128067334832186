import React from 'react'
import connect from './connect'
import Select from 'components/shared/base_react_select'

class Selector extends React.Component {

  onChange = (opt) => {
    this.props.loadProjectGroups(opt.value)
  }

  render () {
    const companies = this.props.companies
    const current = companies.find(company => company.selected )

    return (
      <div>
        <Select
          value={ current }
          options={companies}
          onChange={this.onChange}
        />
        <input type="hidden" name="company_id" value={current && current.value || ''} />
      </div>
    )
  }
}

export default connect(Selector)
