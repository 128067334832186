import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import ElementGroupsSelector from './element_groups_selector'

export default function ElementGroupsSelectorApp(props) {
  return (
    <Provider store={store}>
      <ElementGroupsSelector {...props} />
    </Provider>
  )
}
