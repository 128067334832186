import * as React from 'react'
import BaseReactSelect from '../../../base_react_select'
import { components } from 'react-select'
import CreateElementButton from './create_element_button'

const { I18n } = window as any

type Location = {
  value: number
  label: string
  url: string
}

type Option = {
  value: number
  label: string
}

type Element = {
  value: number
  label: string
  name: string
  photo_thumb: string
}

interface ElementsSelectorProps {
  element_groups: Option[]
  value: number[]
  multi: boolean
  setSelectedElements: (selected_elements: Element[]) => void
  options: Element[]
  selected_elements: Element[]
  location: Location | null
}

function ElementOption(props: any) {
  const { innerRef, innerProps, isDisabled, isFocused, data } = props
  const { label, photo_thumb } = data

  if (isDisabled) {
    return null
  }

  return (
    <tr ref={innerRef} {...innerProps} className={isFocused ? 'table-active option' : 'option'}>
      <td>{photo_thumb && <img src={photo_thumb} alt={label} style={{ width: '50px', height: '50px' }} />}</td>
      <td>{label}</td>
    </tr>
  )
}

function ElementMenuWrapper(props: any) {
  return (
    <components.MenuList {...props}>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>{I18n.t('application.titles.thumbnail')}</th>
            <th>{I18n.t('application.titles.label')}</th>
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </components.MenuList>
  )
}

export default function ElementsSelector(props) {
  const {
    multi,
    options,
    setSelectedElements,
    element_groups,
    selected_elements,
    location,
    createNewLocationElement,
    loadAdditionalFormFieldsLocationElement,
    isFetching,
    createLocationElementSuccess,
    createLocationElementMessage,
    resetCreateLocationElementStatusMessages,
    buttonTitle,
    windowTitle,
    elementsTemplates,
    canCreateElement,
    locations,
    updateLocationElement
  } = props
  const [prevElementGroups, setPrevElementGroups] = React.useState<Option[]>([])

  React.useEffect(() => {
    if (prevElementGroups.length > 0 && element_groups.length === 0) {
      setSelectedElements([])
    }
    setPrevElementGroups(element_groups)
  }, [element_groups, prevElementGroups, setSelectedElements])

  const onChange = (collection) => {
    setSelectedElements(collection)
  }

  return (
    <div className="d-flex">
      <div className="elements-ids-selector-select flex-grow-1">
        <BaseReactSelect
          components={{
            Option: ElementOption,
            MenuList: ElementMenuWrapper,
          }}
          isMulti={multi}
          value={selected_elements}
          options={options}
          onChange={(selected_elements) => onChange(selected_elements)}
          isDisabled={location ? false : true}
        />
        {selected_elements &&
          selected_elements.map((o, index) => (
            <input key={index} type="hidden" name="project_ticket[element_ids][]" value={o.value} />
          ))}
      </div>
      {canCreateElement && (
        <div className="ml-3">
          <CreateElementButton
            location={location}
            createNewLocationElement={createNewLocationElement}
            loadAdditionalFormFieldsLocationElement={loadAdditionalFormFieldsLocationElement}
            isFetching={isFetching}
            createLocationElementSuccess={createLocationElementSuccess}
            createLocationElementMessage={createLocationElementMessage}
            resetCreateLocationElementStatusMessages={resetCreateLocationElementStatusMessages}
            buttonTitle={buttonTitle}
            windowTitle={windowTitle}
            elementsTemplates={elementsTemplates}
            options={locations}
            element_groups={element_groups}
            updateLocationElement={updateLocationElement}
          />
        </div>
      )}
    </div>
  )
}
