import { connect } from 'react-redux'
import { initCompanyList, loadProjectGroups } from 'actions/projects/form_actions'

export default connect(
  (state) => ({
    companies: state.getIn(['projects', 'form', 'companies']).toJS(),
  }),
  {
    initCompanyList,
    loadProjectGroups,
  }
)
