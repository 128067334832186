import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import ServiceCompaniesSelector from './service_companies_selector'

type ServiceCompaniesSelectorAppProps = {
  companyId: number
  canCreateServiceCompany: boolean
  addressCountryOptions: { value: string; label: string }[]
  value: any
  options: { value: string; label: string }[]
  name: string
}
function ServiceCompaniesSelectorApp(props: ServiceCompaniesSelectorAppProps) {
  return (
    <Provider store={store}>
      <ServiceCompaniesSelector {...props} />
    </Provider>
  )
}

export default ServiceCompaniesSelectorApp
