import * as React from 'react'
import { useEffect } from 'react'
import BaseReactSelect from '../../base_react_select'
import CreateLocationButton from './create_location_button'
import connect from './connect'

const LocationSelector = (props) => {
  const {
    options, // initial options, an array of { value, label, url }
    name,
    value,
    canCreateLocation,
    buttonTitle,
    windowTitle,
    // Redux props
    location,
    setLocation,
    createNewLocation,
    resetCreateLocationStatusMessages,
    setAvailableLocations,
    createLocationError,
    createLocationSuccess,
    availableLocations,
    isFetching,
    loadElements,
  } = props

  useEffect(() => {
    setAvailableLocations(options)
    if (value) {
      const defaultLocation = options.find((o) => o.value === value) || null
      setLocation(defaultLocation)
    }
  }, [])

  const saveLocation = (data) => createNewLocation(data)

  const onChange = (opt) => {
    if (opt) {
      setLocation(opt)
      loadElements(
        opt.url,
      )
    } else {
      setLocation({ value: '' })
    }
  }

  return (
    <div className="d-flex">
      <div className="location-selector-select flex-grow-1">
        <BaseReactSelect value={location} options={availableLocations} onChange={onChange} />
        <input type="hidden" value={location?.value || ''} name={name} />
      </div>
      {canCreateLocation && (
        <div className="ml-3">
          <CreateLocationButton
            saveLocation={saveLocation}
            isFetching={isFetching}
            createLocationError={createLocationError}
            createLocationSuccess={createLocationSuccess}
            resetCreateLocationStatusMessages={resetCreateLocationStatusMessages}
            buttonTitle={buttonTitle}
            windowTitle={windowTitle}
          />
        </div>
      )}
    </div>
  )
}

export default connect(LocationSelector)
